.wrapper {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 0 var(--theme-sizes-padding-screeninset);
}

.tab {
  &,
  &:hover,
  &:active,
  &:visited {
    padding: 5px 0;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    margin-bottom: -2px;
    color: var(--theme-colors-fg-1);
    font: inherit;
  }

  &.tabActive {
    border-color: var(--theme-colors-bg-accent1);
    font-weight: 600;
  }
}
