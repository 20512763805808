@import "constants/mediaQueries.scss";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px var(--theme-sizes-padding-screeninset);
  padding: var(--theme-sizes-padding-screeninset);
  margin: 0 auto;
  width: 100%;
  max-width: 2000px;

  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    overflow: hidden;
  }

  h2 {
    margin: 0;
    padding: 0;
  }
}

.section {
  display: flex;
  flex-direction: column;
  padding: var(--theme-sizes-padding-screeninset);

  background-color: var(--theme-colors-bg-1);

  @include tablet {
    overflow: hidden;
  }
}
