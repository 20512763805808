@import "constants/mediaQueries";

@keyframes expand {
  from {
    width: 0;
  }

  to {
    width: var(--slidein-width);
  }
}

.expandWrapper {
  display: contents;

  @include tablet {
    display: flex;
    flex-direction: column;
    --slidein-width: 400px;
    width: var(--slidein-width);
    animation: expand 0.2s ease-out;
  }
}

.wrapper {
  flex: 1;
  position: fixed;
  z-index: 5;
  inset: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background-color: var(--theme-colors-bg-2);
  border-left: 1px solid var(--theme-colors-border-main);
  animation: slideIn 0.3s ease-out;

  @include tablet {
    position: relative;
    width: var(--slidein-width);
  }
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--theme-sizes-padding-screeninset);
  border-bottom: 1px solid var(--theme-colors-border-main);
  background-color: var(--theme-colors-bg-1);

  h2 {
    margin: 0;
  }
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px var(--theme-sizes-padding-screeninset);
}

.actionsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px var(--theme-sizes-padding-screeninset);
  border-top: 1px solid var(--theme-colors-border-main);
  background-color: var(--theme-colors-bg-1);
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;

  border-radius: 1000px;
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: var(--theme-colors-fg-1);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--theme-colors-bg-3);
    color: var(--theme-colors-fg-3);
  }
}
