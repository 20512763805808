.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;

  background-color: rgba(0, 0, 0, 0.2);
}

.spinner {
  font-size: 20px;
}
