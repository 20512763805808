.inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cancelledLabel {
  padding: 3px 10px;
  margin-left: 10px;
  position: relative;
  bottom: 3px;

  font-family: "Open Sans", sans-serif;
  font-size: var(--theme-sizes-font-small);
  font-weight: 600;
  border: 2px solid var(--theme-colors-status-error);
  border-radius: 5px;
}
