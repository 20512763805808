.regnrSearchWrapper {
  display: flex;
  flex-direction: row;

  & :last-child {
    border-left: none;
  }
}

.searchButton {
  margin-left: auto;
}

.myCheckbox {
  margin-bottom: 10px;
}
