.wrapper {
  a {

    &,
    &:visited {
      padding-bottom: 5px;
      color: var(--theme-colors-fg-1);
      text-decoration: none;
      font: inherit;
      font-weight: 600;
      border-bottom: 2px solid transparent;
      transition: border-color 0.1s ease-in-out;
    }

    &.active {
      color: var(--theme-colors-bg-accent1);
      border-color: var(--theme-colors-bg-accent1);
    }
  }
}
