@import "constants/mediaQueries.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--theme-colors-border-main);
  max-height: 25vh;
  overflow: auto;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px var(--theme-sizes-padding-screeninset);

  @include tablet {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.pageControlsRow {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include tablet {
    flex-direction: row;
    align-items: flex-end;
  }
}
