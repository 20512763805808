@import "constants/mediaQueries.scss";

/**
* Theme color definitions.
* OBS: run `npm run updateStyleRules` to update the style rules in the
* `.stylelintrc.json` file.
*/
:root {
  /** Background colors */
  --theme-colors-bg-accent1: #e30613;
  --theme-colors-bg-1: #ffffff;
  --theme-colors-bg-2: #f1f1f1;
  --theme-colors-bg-3: #cccccc;
  --theme-colors-bg-selected: #49f;
  --theme-colors-bg-overlay: rgba(0, 0, 0, 0.5);

  /** Foreground colors */
  --theme-colors-fg-accent1: #ffffff;
  --theme-colors-fg-1: #000000;
  --theme-colors-fg-2: #333333;
  --theme-colors-fg-3: #000000;
  --theme-colors-fg-selected: #ffffff;

  /** Border colors */
  --theme-colors-border-main: #d7d7d7;
  --theme-colors-border-input: #cccccc;

  /** Status colors */
  --theme-colors-status-success: #00a300;
  --theme-colors-status-warning: #e38300;
  --theme-colors-status-error: #e30613;

  /** Padding and margin sizes */
  --theme-sizes-padding-screeninset: 10px;

  /** Font sizes */
  --theme-sizes-font-large: 28px;
  --theme-sizes-font-medium: 20px;
  --theme-sizes-font-small: 14px;
}

@include desktop {
  :root {
    --theme-sizes-padding-screeninset: 20px;
  }
}

html,
body,
div#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  font-size: var(--theme-sizes-font-small);
  background-color: var(--theme-colors-bg-2);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: var(--theme-sizes-font-large);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}
