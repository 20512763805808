$MediaQuery-tablet-minwidth: 768px;
$MediaQuery-desktop-minwidth: 1200px;

@mixin phone {
  @media only screen and (max-width: $MediaQuery-tablet-minwidth) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $MediaQuery-tablet-minwidth) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $MediaQuery-desktop-minwidth) {
    @content;
  }
}
