@import "constants/mediaQueries.scss";

.centerContent {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 660px;
  max-width: 60vw;
  width: 60vw;

  @include phone {
    min-width: 0;
    max-width: calc(100vw - 2 * var(--theme-sizes-padding-screeninset));
    width: calc(100vw - 2 * var(--theme-sizes-padding-screeninset));
    margin: 0 var(--theme-sizes-padding-screeninset);
  }

  @include desktop {
    max-width: 870px;
    width: 870px;
  }
}
