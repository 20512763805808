.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  background-color: var(--theme-colors-bg-1);

  &:hover .editFab {
    opacity: 1;
  }

  :global {
    .ql-container {
      font-family: inherit;
      font-size: 1rem;

      .ql-editor {
        line-height: normal;
        min-height: 200px;

        h1 {
          font-size: 26px;
        }

        h2 {
          font-size: 22px;
        }

        h3 {
          font-size: 18px;
        }
      }
    }

    .ql-tooltip.ql-hidden {
      display: none;
    }
  }
}

.quillWrapper {
  flex: 1;
  background-color: white;
  color: black;
  position: relative;
  overflow: auto;
}

.lengthError {
  position: absolute;
  right: 0;
  top: 0;
  color: red;
  font-size: 14px;
  font-style: italic;
  background-color: white;
  border-right: 1px solid rgb(204, 204, 204);
  border-top: 1px solid rgb(204, 204, 204);
  padding: 10px;
}

.readOnlyWrapper {
  overflow: hidden;

  :global {
    .ql-editor {
      padding: 10px var(--theme-sizes-padding-screeninset);
    }

    img {
      max-width: 100%;
    }

    blockquote {
      border-left: 3px solid rgb(229, 229, 229);
      padding-left: 25px;
    }

    pre {
      background-color: #fff;
      border: 1px solid rgb(229, 229, 229);
      color: #000;
      border-radius: 5px;
      padding: 10px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px var(--theme-sizes-padding-screeninset);
}

.myStyledFontAwesome {
  margin-right: 5px;
}

button.editFab {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 20px;
  padding: 0;

  border-radius: 1000px;
  font-size: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
}
