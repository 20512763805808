@import "constants/mediaQueries.scss";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.head {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--theme-colors-border-main);
  background-color: var(--theme-colors-bg-1);

  h1 {
    flex: 1;
    margin: 0;
    padding: 20px var(--theme-sizes-padding-screeninset) 10px;
  }
}
