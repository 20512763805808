@import "constants/mediaQueries.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  min-height: 50vh;

  @include tablet {
    grid-area: 1 / 2 / 3 / 3;
    margin: 20px 0;
    margin-right: 5vw;
  }
}

.richTextEditor {
  flex: 1;
}
