@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  background: var(--theme-colors-bg-overlay);
  animation: fadeIn 0.3s;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.modalBody {
  display: flex;
  flex-direction: column;

  background: var(--theme-colors-bg-1);
}

.modalContent {
  flex: 1;
  padding: 20px;
}

.modalTitleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.modalTitle {
  flex: 1;
  margin: 20px;
  margin-bottom: 0;
  padding-bottom: 5px;

  font-size: var(--theme-sizes-font-large);
}

.modalTitleCloseButton {
  align-self: flex-start;
  padding: 5px;
  margin: 20px;
  margin-bottom: 0;
  border: 0;
  font-size: 25px;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

.modalButtons {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 20px 20px;
  gap: 10px;
}
