.linkButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;

  color: var(--theme-colors-bg-accent1);
  text-decoration: none;
  font: inherit;
  font-style: italic;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
