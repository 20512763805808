.outerWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.searchWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
