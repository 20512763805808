.wrapper {
  background-color: var(--theme-colors-bg-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 20px;
  color: var(--theme-colors-fg-1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media screen and (min-width: 768px) {
    padding: 15px 20px;
  }

  @media screen and (min-width: 1280px) {
    padding: 20px 20px;
  }
}

.logo {
  background-image: url("../../assets/Logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  --logo-width: 400px;
  --logo-height: 40px;
  width: calc(var(--logo-width) * 0.6);
  height: calc(var(--logo-height) * 0.6);
  margin: 0;

  @media screen and (min-width: 768px) {
    width: calc(var(--logo-width) * 0.8);
    height: calc(var(--logo-height) * 0.8);
  }

  @media screen and (min-width: 1280px) {
    width: var(--logo-width);
    height: var(--logo-height);
  }
}

.logOutLink {
  color: var(--theme-colors-fg-1);
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: var(--theme-colors-fg-1);
  }
}

.pageLinks {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style: none;
}
