@import "constants/mediaQueries.scss";

.inputRow {
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    margin-left: auto;
    margin-top: auto;
  }

  @include tablet {
    flex-direction: row;
    align-items: flex-end;
  }
}

.flex {
  flex: 1;
}
